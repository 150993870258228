import axios from "axios"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import AnimatedNumber from "react-animated-number/build/AnimatedNumber"
import { Col, Container, Row } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import CustomAccordion from "../../components/common/accordian.js"
import Buttons from "../../components/common/button"
import Frame from "../../components/common/frame.js"
import SVGIcon from "../../components/common/SVGIcon"
import { preventInitialZero } from "../../components/common/util-functions"
import Navigation from "../../components/navigation.js"
import {
  ITPricingCurrency,
  ITPricingFeaturesOne,
  ITPricingFeaturesThree,
  ITPricingFeaturesTwo,
} from "../../constants/globalMessages"
import "../../styles/it-pricing.scss"

const allITFeatures = [
  ITPricingFeaturesOne,
  ITPricingFeaturesTwo,
  ITPricingFeaturesThree,
]

const ItPricing = ({ data }) => {
  const { faqs, pages } = data.SuperOps
  const { pageBlock, seo, newField } = pages[0]
  const heroContent = newField[0]

  const [currency, setCurrency] = useState("USD")
  useEffect(() => {
    axios.get(process.env.IP_STACK_API).then(function(response) {
      if (
        response.data.continent_name === "Europe" &&
        response.data.country_name !== "United Kingdom"
      ) {
        setCurrency("EURO")
      } else if (response.data.country_name === "United Kingdom") {
        setCurrency("GBP")
      } else if (
        response.data.country_code === "AU" ||
        response.data.country_name === "Australia"
      ) {
        setCurrency("AUD")
      }
    })
  }, [])

  const [endPoints, setEndPoints] = useState(100)
  const [paymentMonthly, setPaymentMonthly] = useState(
    100 * ITPricingCurrency[currency].monthly[0]
  )
  const [paymentYearly, setPaymentYearly] = useState(
    100 * ITPricingCurrency[currency].yearly[0]
  )

  const checkEPRange = value => {
    return value >= 0 && value <= 10000
  }

  const [monthlyChart, setMonthlyChart] = useState([
    [
      "1-100",
      `100*${ITPricingCurrency[currency].symbol}${ITPricingCurrency[currency].monthly[0]}`,
      `${ITPricingCurrency[currency].symbol}${100 *
        ITPricingCurrency[currency].monthly[0]}`,
    ],
  ])

  const [yearlyChart, setYearlyChart] = useState([
    [
      "1-100",
      `100*${ITPricingCurrency[currency].symbol}${ITPricingCurrency[currency].yearly[0]}`,
      `${ITPricingCurrency[currency].symbol}${100 *
        ITPricingCurrency[currency].yearly[0]}`,
    ],
  ])

  useEffect(() => {
    setPaymentMonthly(100 * ITPricingCurrency[currency].monthly[0])
    setPaymentYearly(100 * ITPricingCurrency[currency].yearly[0])
    setMonthlyChart([
      [
        "1-100",
        `100*${ITPricingCurrency[currency].symbol}${ITPricingCurrency[currency].monthly[0]}`,
        `${ITPricingCurrency[currency].symbol}${100 *
          ITPricingCurrency[currency].monthly[0]}`,
      ],
    ])
    setYearlyChart([
      [
        "1-100",
        `100*${ITPricingCurrency[currency].symbol}${ITPricingCurrency[currency].yearly[0]}`,
        `${ITPricingCurrency[currency].symbol}${100 *
          ITPricingCurrency[currency].yearly[0]}`,
      ],
    ])
  }, [currency])

  const calculatePrice = value => {
    if (checkEPRange(value)) {
      let ptCpy = 0
      let monthly = 100 * ITPricingCurrency[currency].monthly[0],
        yearly = 100 * ITPricingCurrency[currency].yearly[0],
        tempMC = [
          [
            "1-100",
            `100*${ITPricingCurrency[currency].symbol}${ITPricingCurrency[currency].monthly[0]}`,
            `${ITPricingCurrency[currency].symbol}${100 *
              ITPricingCurrency[currency].monthly[0]}`,
          ],
        ],
        tempYC = [
          [
            "1-100",
            `100*${ITPricingCurrency[currency].symbol}${ITPricingCurrency[currency].yearly[0]}`,
            `${ITPricingCurrency[currency].symbol}${100 *
              ITPricingCurrency[currency].yearly[0]}`,
          ],
        ]
      setEndPoints(value ? preventInitialZero(value) : 0)
      ptCpy = value ? preventInitialZero(value) : 0

      if (ptCpy - 100 > 0) {
        ptCpy = ptCpy - 100
        monthly = 100 * ITPricingCurrency[currency].monthly[0]
        yearly = 100 * ITPricingCurrency[currency].yearly[0]
        if (ptCpy - 400 > 0) {
          ptCpy = ptCpy - 400
          monthly = monthly + 400 * ITPricingCurrency[currency].monthly[1]
          yearly = yearly + 400 * ITPricingCurrency[currency].yearly[1]
          tempMC.push([
            "101-500",
            `400*${ITPricingCurrency[currency].symbol}${ITPricingCurrency[currency].monthly[1]}`,
            `${ITPricingCurrency[currency].symbol}${parseInt(
              400 * ITPricingCurrency[currency].monthly[1]
            )}`,
          ])
          tempYC.push([
            "101-500",
            `400*${ITPricingCurrency[currency].symbol}${ITPricingCurrency[currency].yearly[1]}`,
            `${ITPricingCurrency[currency].symbol}${parseInt(
              400 * ITPricingCurrency[currency].yearly[1]
            )}`,
          ])
          if (ptCpy - 500 > 0) {
            ptCpy = ptCpy - 500
            monthly = monthly + 500 * ITPricingCurrency[currency].monthly[2]
            yearly = yearly + 500 * ITPricingCurrency[currency].yearly[2]
            tempMC.push([
              "501-1000",
              `500*${ITPricingCurrency[currency].symbol}${ITPricingCurrency[currency].monthly[2]}`,
              `${ITPricingCurrency[currency].symbol}${parseInt(
                500 * ITPricingCurrency[currency].monthly[2]
              )}`,
            ])
            tempYC.push([
              "501-1000",
              `500*${ITPricingCurrency[currency].symbol}${ITPricingCurrency[currency].yearly[2]}`,
              `${ITPricingCurrency[currency].symbol}${parseInt(
                500 * ITPricingCurrency[currency].yearly[2]
              )}`,
            ])
            if (ptCpy > 0) {
              monthly = monthly + ptCpy * ITPricingCurrency[currency].monthly[3]
              yearly = yearly + ptCpy * ITPricingCurrency[currency].yearly[3]
              tempMC.push([
                `1001-${value}`,
                `${ptCpy}*${ITPricingCurrency[currency].symbol}${ITPricingCurrency[currency].monthly[3]}`,
                `${ITPricingCurrency[currency].symbol}${parseInt(ptCpy *
                  ITPricingCurrency[currency].monthly[3])}`,
              ])
              tempYC.push([
                `1001-${value}`,
                `${ptCpy}*${ITPricingCurrency[currency].symbol}${ITPricingCurrency[currency].yearly[3]}`,
                `${ITPricingCurrency[currency].symbol}${parseInt(ptCpy *
                  ITPricingCurrency[currency].yearly[3])}`,
              ])
            }
          } else {
            monthly = monthly + ptCpy * ITPricingCurrency[currency].monthly[2]
            yearly = yearly + ptCpy * ITPricingCurrency[currency].yearly[2]
            tempMC.push([
              `501-${value}`,
              `${ptCpy}*${ITPricingCurrency[currency].symbol}${ITPricingCurrency[currency].monthly[2]}`,
              `${ITPricingCurrency[currency].symbol}${parseInt(ptCpy *
                ITPricingCurrency[currency].monthly[2])}`,
            ])
            tempYC.push([
              `501-${value}`,
              `${ptCpy}*${ITPricingCurrency[currency].symbol}${ITPricingCurrency[currency].yearly[2]}`,
              `${ITPricingCurrency[currency].symbol}${parseInt(ptCpy *
                ITPricingCurrency[currency].yearly[2])}`,
            ])
          }
        } else {
          monthly = monthly + ptCpy * ITPricingCurrency[currency].monthly[1]
          yearly = yearly + ptCpy * ITPricingCurrency[currency].yearly[1]
          tempMC.push([
            `101-${value}`,
            `${ptCpy}*${ITPricingCurrency[currency].symbol}${ITPricingCurrency[currency].monthly[1]}`,
            `${ITPricingCurrency[currency].symbol}${parseInt(ptCpy *
              ITPricingCurrency[currency].monthly[1])}`,
          ])
          tempYC.push([
            `101-${value}`,
            `${ptCpy}*${ITPricingCurrency[currency].symbol}${ITPricingCurrency[currency].yearly[1]}`,
            `${ITPricingCurrency[currency].symbol}${parseInt(ptCpy *
              ITPricingCurrency[currency].yearly[1])}`,
          ])
        }
      }
      setPaymentMonthly(monthly)
      setPaymentYearly(yearly)
      setMonthlyChart(tempMC)
      setYearlyChart(tempYC)
    }
  }

  const subscriptionTerm = ["Monthly", "Yearly"]
  const [selectedOption, setSelectedOption] = useState("Monthly")
  const handleRadioChange = event => {
    setSelectedOption(event.target.value)
  }

  const [isMonthly, setIsMonthly] = useState(false)

  useEffect(() => {
    const featSection = document.getElementById("it_feature_section")
    if (featSection) {
      const innerElement = document
        .getElementById("inner_it_feature")
        ?.getBoundingClientRect()
      featSection.style.height = `${innerElement.height}px`
    }
  }, [])

  const [showFeat, setShowFeat] = useState(false)

  const featureTop = () => {
    const featSection = document.getElementById("it_feature_section")
    if (featSection) {
      const pst = featSection.getBoundingClientRect().top + window.scrollY
      window.scrollTo({
        top: pst - 290,
        behavior: "smooth",
      })
    }
  }

  useEffect(() => {
    const intercomShow = () => {
      if (typeof window.Intercom !== "undefined") {
        window.Intercom("show")
      }
    }

    const handleIntercom = e => {
      e.stopPropagation()
      intercomShow()
    }

    const chatBoxes = document.querySelectorAll(".chatId2")

    const faqAnswer = document.querySelector(".dynamic-currency > p")
    faqAnswer.innerText = `${ITPricingCurrency[currency].symbol}${ITPricingCurrency[currency].monthlyStr[0]}`

    chatBoxes.forEach(chatBox => {
      chatBox.addEventListener("click", handleIntercom)
    })

    return () => {
      chatBoxes.forEach(chatBox => {
        chatBox.removeEventListener("click", handleIntercom)
      })
    }
  })

  return (
    <div className="it-pricing">
      <Frame seo={seo} footerClass="footer" newButton>
        <header>
          <Navigation page="Home" dskScroll={400} newButton />
        </header>

        <section className="hero">
          <Container className="Layout-container d-flex flex-wrap">
            <Col lg={6}>
              <h1 className="font-roboto fw-bold mb24 heading down-to-up-1">
                {heroContent.heroHeading.text}
              </h1>
              <p className="mb24 p18 description down-to-up-2">
                {heroContent.content.text}
              </p>
              <div className="d-flex flex-wrap benefit-wrap mb40 down-to-up-3">
                {heroContent.heroExcerptList.map(el => {
                  return (
                    <div className="benefits d-flex align-items-center gap-6">
                      <SVGIcon name="greenTick" className="tick-logo" />
                      <p className="name m-0 p18 fw-500">{el}</p>
                    </div>
                  )
                })}
              </div>
              <Buttons
                theme="primary-new white-new  down-to-up-4"
                link={heroContent.primaryButtonLink}
                text={heroContent.primaryButtonText}
              />
              <div className="d-mob d-flex align-items-center flex-column mt38 down-to-up-5">
                <p className="p14 fw-bold text-pink m-0">
                  {newField[1].heading[2]}
                </p>
                <p className="p14 mb12">{newField[1].heading[3]}</p>
                <div class="switch-button text-center mb30">
                  <div
                    class={`switch-button-checkbox ${
                      isMonthly ? "monthly-plan" : "yearly-plan"
                    }`}
                  >
                    {" "}
                  </div>

                  <div className="switch-button-label prelative d-flex cursor-pointer z-1">
                    <span
                      class="switch-button-label-1 fw-bold d-inline-flex align-items-center"
                      onClick={() => setIsMonthly(true)}
                      style={{
                        color: isMonthly ? "#ffff" : "#170426",
                      }}
                    >
                      MONTHLY
                    </span>
                    <span
                      class="switch-button-label-2 fw-bold d-inline-flex align-items-center"
                      onClick={() => setIsMonthly(false)}
                      style={{
                        marginLeft: "0px",
                        color: !isMonthly ? "#ffff" : "#170426",
                      }}
                    >
                      ANNUALLY
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} className="right br10 down-to-up-3">
              <div className="d-flex justify-content-between align-items-end">
                <div>
                  <h3 className="text-pink font-roboto fw-bold">
                    {newField[1].heading[0]}
                  </h3>
                  <p className="p18 fw-600 mb30">{newField[1].heading[1]}</p>
                  <div className="line" />
                </div>
                <div className="text-align-right d-dsk">
                  <p className="p14 fw-bold text-pink m-0">
                    {newField[1].heading[2]}
                  </p>
                  <p className="p14 mb12">{newField[1].heading[3]}</p>
                  <div class="switch-button text-center mb30">
                    <div
                      class={`switch-button-checkbox ${
                        isMonthly ? "monthly-plan" : "yearly-plan"
                      }`}
                    >
                      {" "}
                    </div>

                    <div className="switch-button-label prelative d-flex cursor-pointer z-1">
                      <span
                        class="switch-button-label-1 fw-bold d-inline-flex align-items-center"
                        onClick={() => setIsMonthly(true)}
                        style={{
                          color: isMonthly ? "#ffff" : "#170426",
                        }}
                      >
                        MONTHLY
                      </span>
                      <span
                        class="switch-button-label-2 fw-bold d-inline-flex align-items-center"
                        onClick={() => setIsMonthly(false)}
                        style={{
                          marginLeft: "0px",
                          color: !isMonthly ? "#ffff" : "#170426",
                        }}
                      >
                        ANNUALLY
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {newField[1].title.map((item, idx) => {
                  return (
                    <div className="d-flex justify-content-between mt30">
                      <p className="m-0 p18">{item}</p>
                      <p className="m-0 p24 font-roboto fw-500">
                        {`${ITPricingCurrency[currency].symbol}${
                          isMonthly
                            ? ITPricingCurrency[currency].monthlyStr[idx]
                            : ITPricingCurrency[currency].yearlyStr[idx]
                        }`}
                      </p>
                    </div>
                  )
                })}
              </div>
            </Col>
            <p className="note text-pink p18 fw-500 w-100 mt16 down-to-up-5">
              {newField[1].heading[4]}
            </p>
          </Container>
        </section>

        <Fade bottom duration={800}>
          <div className="d-flex justify-content-center align-items-center mb64">
            <p
              className="view-all-btn d-flex align-items-center gap-8 font-roboto p20 fw-600 cursor-pointer m-0"
              onClick={() => {
                setShowFeat(!showFeat)
              }}
            >
              {"View all features"}
              <SVGIcon
                name="nav-arrow-down"
                className={`drpdown-arr ${showFeat ? "active" : ""}`}
              />
            </p>
          </div>
        </Fade>

        <section
          id="it_feature_section"
          className={`it-features overflow-hidden ${
            showFeat ? "fade-in-effect" : "height-zero fade-out-effect"
          }`}
        >
          <Container id="inner_it_feature" className="Layout-container">
            <h4 className="font-roboto fw-bold text-center mb40">
              {"Features included with Prime"}
            </h4>
            <Row>
              {allITFeatures.map(element => {
                let delayCount = 0

                return (
                  <Col lg={4}>
                    {element.map(item => {
                      if (delayCount > 5) {
                        delayCount = 0
                      }
                      return (
                        <>
                          <span className="dspnone">{delayCount++}</span>
                          <div className="feat-card mb24">
                            <p className="p16 fw-bold mb40">{item.name}</p>
                            {item.features.map(feat => {
                              return (
                                <div className="d-flex justify-content-between align-items-center mt20">
                                  <p className="p13 m-0">{feat.feature}</p>
                                  {feat.available === "yes" ? (
                                    <SVGIcon
                                      name="greenTick"
                                      className="tick-logo"
                                    />
                                  ) : (
                                    <div
                                      className="tip-icon prelative"
                                      tooltip-text={feat.available}
                                    >
                                      <SVGIcon name="pricing/tooltip" />
                                    </div>
                                  )}
                                </div>
                              )
                            })}
                          </div>
                        </>
                      )
                    })}
                  </Col>
                )
              })}
            </Row>
            <Fade bottom duration={800}>
              <div className="d-flex justify-content-center align-items-center pb40 pt80">
                <p
                  className="view-all-btn d-flex align-items-center gap-8 font-roboto p20 fw-600 cursor-pointer m-0"
                  onClick={() => {
                    featureTop()
                    setShowFeat(!showFeat)
                  }}
                >
                  {"View all features"}
                  <SVGIcon
                    name="nav-arrow-down"
                    className={`drpdown-arr ${showFeat ? "active" : ""}`}
                  />
                </p>
              </div>
            </Fade>
          </Container>
        </section>

        <section className="calculator">
          <Fade bottom duration={800}>
            <Container className="mx-auto bg d-flex flex-wrap justify-content-center align-items-center gap-24">
              <div className="left">
                <h4 className="font-roboto fw-bold heading mb24">
                  {newField[2].title[0]}
                </h4>
                <p className="p16 fw-600 font-roboto">{newField[2].title[1]}</p>
                <div className="d-flex flex-wrap gap-24 mb36">
                  {subscriptionTerm.map(item => {
                    return (
                      <div className="d-flex align-items-center gap-8">
                        <input
                          type="radio"
                          id={item}
                          value={item}
                          checked={selectedOption === item}
                          onChange={handleRadioChange}
                        />
                        <label for={item} className=" fw-500 cursor-pointer">
                          <p className="m-0 p14">{item}</p>
                        </label>
                      </div>
                    )
                  })}
                </div>
                <div className="d-flex flex-wrap align-items-end justify-content-between gap-16">
                  <div>
                    <p className="p16 font-roboto fw-600 mb8">
                      {newField[2].title[2]}
                    </p>
                    <p className="p10 m-0">{newField[2].title[3]}</p>
                  </div>
                  <input
                    type="number"
                    value={endPoints}
                    min="0"
                    max="10000"
                    className="input-box fw-bold d-flex justify-content-center text-center m-0"
                    onChange={e => calculatePrice(e.target.value)}
                  />
                </div>
              </div>
              <div className="right d-flex justify-content-between align-items-center flex-wrap gap-24">
                <div className="amount-box text-center overflow-hidden w-100">
                  <h1 className="font-roboto mb24 fw-400">
                    {ITPricingCurrency[currency].symbol}
                    <AnimatedNumber
                      component="k"
                      value={parseInt(
                        selectedOption === "Monthly"
                          ? paymentMonthly
                          : paymentYearly
                      )}
                      duration={300}
                      stepPrecision={0}
                    />
                  </h1>
                  <p className="p12 mb36">{`per month, billed ${
                    selectedOption === "Monthly" ? "monthly" : "annually"
                  }`}</p>
                  <Buttons
                    theme={`primary-new`}
                    text={"GET STARTED FOR FREE"}
                    link="/signup"
                  />
                </div>
                <div className="price-breakup">
                  <p className="p14">
                    {newField[2].title[4]}
                    <span className="fw-bold">{`${
                      endPoints > 99 ? endPoints : "100"
                    } devices`}</span>
                  </p>
                  <div className="d-flex justify-content-between">
                    <p className="p10 fw-bold mb8">{"DEVICES"}</p>
                    <p className="p10 fw-bold mb8">{"PRICING"}</p>
                  </div>
                  <div className="chart-cover">
                    {(selectedOption === "Monthly"
                      ? monthlyChart
                      : yearlyChart
                    ).map(item => {
                      return (
                        <div className={`d-flex justify-content-between rack`}>
                          {item.map((str, idx) => {
                            return (
                              <p
                                className={`m-0 p14 text text-${idx + 1} ${
                                  idx === 2
                                    ? "font-roboto text-deep-purple fw-500"
                                    : ""
                                }`}
                              >
                                {str}
                              </p>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                  <div className="total gap-16 d-flex justify-content-end mt16">
                    <p className="">{"Total"}</p>
                    <p className="amt fw-bold font-roboto">{`${
                      ITPricingCurrency[currency].symbol
                    }${parseInt(
                      selectedOption === "Monthly"
                        ? paymentMonthly
                        : paymentYearly
                    )}`}</p>
                  </div>
                </div>
              </div>
            </Container>
          </Fade>
        </section>

        <section className="faq-container">
          <Fade bottom duration={800}>
            <div className="main">
              <div className="faq super-pricing-faq mt0">
                <Container className="Layout-container">
                  <Row className="justify-content-between">
                    <Col lg={4}>
                      {parse(pageBlock[0].content.html)}
                      {parse(pageBlock[1].content.html)}
                    </Col>
                    <Col lg={8}>
                      <div className="section">
                        <CustomAccordion items={faqs} />
                        <Row>
                          <Col lg={5}>
                            <div className="pricing-faq-title fw-bold">
                              {parse(pageBlock[2].content.html)}
                            </div>
                          </Col>
                          <Col lg={7}>
                            <span className="p16">
                              <p className="pricing-faq-desc">
                                {parse(pageBlock[3].content.html)}
                              </p>
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Fade>
        </section>
      </Frame>
    </div>
  )
}
export default ItPricing

export const query = graphql`
  query itPricing {
    SuperOps {
      pages(where: { title: "IT-Pricing" }) {
        pageBlock {
          id
          content {
            html
            text
          }
        }
        seo {
          title
          description
          keywords
          image {
            url
          }
        }
        newField {
          ... on SuperOps_Hero {
            heroHeading: heading {
              text
            }
            content {
              text
            }
            heroExcerptList
            primaryButtonText
            primaryButtonLink
          }
          ... on SuperOps_Card {
            heading
            title
          }
        }
      }
      faqs(where: { page: "IT-Pricing" }) {
        category
        question
        answerrt {
          html
        }
      }
    }
  }
`
